import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
} from 'angular-calendar';
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from '../../shared.module';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CalendarModule as ngPrimeCalendarModule } from 'primeng/calendar';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader/loader.service';
import { UserService } from '../../services/user/user.service';
import { RouterModule } from '@angular/router';
import moment from 'moment';
import lodash from 'lodash';
import { CommonServiceService } from '../../services/common-service.service';
import { EventService } from '../../services/event/event.service';

const colors = {
  pending: {
    primary: '#FFFF00', // Yellow
  },
  completed: {
    primary: '#008000', // Green
  },
  cancelled: {
    primary: '#FF0000', // Red
  },
  default: {
    primary: '#0000FF', // Blue
  },
};

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    CalendarModule,
    SharedModule,
    ColorPickerModule,
    ngPrimeCalendarModule,
    RouterModule
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent {
  @ViewChild('target') targetElement: ElementRef;
  @Output() currentEventData = new EventEmitter();
  @Output() dayClicked = new EventEmitter<{ date: Date; events: CalendarEvent[] }>();
  @Output() hideForm = new EventEmitter();
  @Input() orgStudioData: any = {};
  @Input() allSchoolList: any = [];
  @Input() searchOrgTxt: string = ''
  view: CalendarView = CalendarView.Month;
  eventSubscription: Subscription;
  CalendarView = CalendarView;
  modalData:
    | {
        action: string;
        event: CalendarEvent;
      }
    | undefined;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events?.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  events: any[] = [];
  // selectedEventList: any[] = [];
  activeDayIsOpen: boolean = true;
  @Input() selectedDate = this.formatDate(new Date());
  viewDate: Date = new Date();
  today = new Date();
  isShowEventForm: boolean = false;
  gridDataList = [{ field: '', headerName: 'Action' }];
  selectedRowIndex: number = -1;
  allEventList: any[] = [];

  isFullTextDisplayed: boolean = false;
  fullText: string = '';
  futureEventList: any[] = [];
  constructor(
    private db: AngularFireDatabase,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    private userService: UserService,
    private commonService: CommonServiceService,
    private eventService: EventService
  ) {}

  async ngOnInit() {
    await this.getEvents();
  }

  async ngOnChanges() {
    this.userService.studioID;
    this.viewDate = new Date(this.selectedDate);
    this.filterEventList()
  }

  async getEvents(): Promise<void> {
    this.loaderService.show()
    return new Promise<void>((resolve, reject) => {
      let studioID = this.userService.studioID;
      if (!(this.userService.studioID)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Something went wrong. Please try again later.`,
        });
        this.loaderService.hide()
        return;
      }

      let eventPath = `/portal/events/${studioID}`;
      this.eventSubscription = this.db
        .object(eventPath)
        .valueChanges()
        .subscribe({
          next: (data) => {
            this.loaderService.hide()
            if (data) {
              let eventList: any = [];
              Object.entries(data).forEach(([orgId, cycles]) => {
                Object.entries(cycles).forEach(([cycle, events]) => {
                  Object.entries(events).forEach(([eventId, eventData]) => {
                    if (eventData?.meta?.eventDate) {
                      const event = {
                        ...eventData,
                        studioId: studioID,
                        orgId: orgId,
                        cycle: cycle,
                        eventId: eventId,
                        title: eventData.meta?.displayName || '',
                        start: eventData?.meta?.eventDate
                          ? new Date(eventData.meta.eventDate)
                          : '',
                        end: eventData?.meta?.eventEndDate
                          ? new Date(eventData.meta.eventEndDate)
                          : '',
                      };
                      eventList.push(event);
                    }
                  });
                });
              });
              this.events = eventList;
              this.allEventList = eventList;
              this.filterEventList()
              if (!!(!this.events.length)) {
                // this.selectedEventList = [];
                this.futureEventList = [];
              } else {
                /* For get selectedEventList */
                // this.selectedEventList = this.events?.filter((event) =>
                //   isSameDay(event.start, new Date(this.selectedDate))
                // );

                /* For get futureEventList */
                let selectedDate = new Date(this.selectedDate)
                this.futureEventList = this.getFutureEvents(this.events, selectedDate);
              }
              resolve();
            } else {
              this.loaderService.hide()
              this.events = [];
              // this.selectedEventList = [];
              this.futureEventList = [];
              resolve();
            }
          },
          error: (err) => {
            this.loaderService.hide()
            reject(new Error('No data found'));
          },
        });
    });
  }

  filterEventList() {
    // Filter events based on orgIds if available
    this.events = this.orgStudioData?.orgIds && this.orgStudioData.orgIds.length > 0
      ? this.allEventList.filter(obj => this.orgStudioData.orgIds.includes(obj.orgId))
      : this.allEventList;

    // // Show info message if no events found
    // if (this.events.length === 0) {
    //   this.messageService.add({
    //     severity: 'info',
    //     detail: `No Events found.`,
    //   });
    // }

    // Filter events based on selected date
    const selectedDate = new Date(this.selectedDate);
     /* For get selectedEventList */
    // this.selectedEventList = this.events.filter(event => isSameDay(event.start, selectedDate));

     /* For get futureEventList */
    this.futureEventList = this.getFutureEvents(this.events, selectedDate);

    // Filter events based on search text
    if (this.searchOrgTxt) {
      const searchTxtLower = this.searchOrgTxt.toLowerCase();
      // this.selectedEventList = this.selectedEventList.filter(item => item?.title.toLowerCase().includes(searchTxtLower));
      this.futureEventList = this.futureEventList.filter(item => item?.title.toLowerCase().includes(searchTxtLower));
    }
  }

  getFutureEvents(events, selectedDate) {
    const selectedDateMoment = moment(selectedDate);

    return events
      ?.filter((event) => {
        const eventDate = moment(event.start);
        const eventEndDate = event?.end
          ? moment(event.end)
          : moment(event.start);

        return (
          eventDate.isSameOrAfter(selectedDateMoment, 'day') ||
          eventEndDate.isSameOrAfter(selectedDateMoment, 'day')
        );
      })
      .sort((a, b) => {
        const momentAStart = moment(a.start);
        const momentBStart = moment(b.start);

        return momentAStart.valueOf() - momentBStart.valueOf();
      });
  }

  getOrgByOrgId(orgId) {
    if (!orgId) {
      return '';
    }
    return this.allSchoolList?.filter((org) => org?.key == orgId)[0]?.orgName;
  }

  getColorForStatus(status) {
    switch (status) {
      case 0:
        return colors.pending;
      case 1:
        return colors.completed;
      case 2:
        return colors.cancelled;
      default:
        return colors.default;
    }
  }

  dayChanged({ date, events }: { date: Date; events: CalendarEvent[] }){
    this.selectedDate = this.formatDate(date);
    this.hideForm.emit();
    let event = { eventDate: date };
    this.addEditEvent('add', event, this.isShowEventForm);
    // this.selectedEventList = events || [];
    this.futureEventList = this.getFutureEvents(this.events, date);

    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
    }
    this.isShowEventForm = false;
    // TODO: Improve scrolling behavior for a more efficient solution in the future.
    setTimeout(() => {
      this.targetElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 400);
  }

  eventTimesChanged(event: any): void {
    this.events = this.events.map((iEvent: any) => {
      if (iEvent === event) {
        return {
          ...event,
        };
      }
      return iEvent;
    });
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log('event: ', event);
    console.log('action: ', action);
  }

  async deleteEvent(eventToDelete: any) {
    let studioId = eventToDelete?.studioId;
    let orgId = eventToDelete?.orgId;
    let cycle = eventToDelete?.cycle;
    let eventId = eventToDelete?.eventId;
    const eventPath = `/portal/events/${studioId}/${orgId}/${cycle}/${eventId}`;
    const confirmedSlots = lodash.get(eventToDelete, 'slots', false) ? lodash.filter(this.commonService.convertObjToArr(eventToDelete.slots), (slot: any) => slot.status_appointment == 'confirmed').length : 0;
    const invitedInds = lodash.get(eventToDelete, 'subjects', false) ? eventToDelete.subjects.length : 0;
    let msg = `Are you sure you want to delete ${eventToDelete.meta.displayName} event at ${this.getOrgByOrgId(orgId)}? This operation is irreversible.`;
    if (invitedInds > 0) {
      msg = `The event ${eventToDelete.meta.displayName} currently has ${invitedInds} invited individuals.<br>Are you certain you want to delete it? This operation is irreversible.`
    }
    if (invitedInds > 0 && confirmedSlots > 0) {
      msg = `The event ${eventToDelete.meta.displayName} currently has ${invitedInds} invited individuals and ${confirmedSlots} confirmed bookings.<br>Are you certain you want to delete it? This operation is irreversible.`
    }
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: msg,
      header: 'Confirmation',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      rejectButtonStyleClass: 'p-button-text',
      accept: async () => {
        let archivePath = `${studioId}/${orgId}/${cycle}/${eventId}`
        eventToDelete['timestamp_archived'] = moment().valueOf();
        delete eventToDelete?.studioId
        delete eventToDelete?.orgId
        delete eventToDelete?.eventId
        delete eventToDelete?.title
        delete eventToDelete?.schoolName
        delete eventToDelete?.cycle
        delete eventToDelete?.start
        let [, deleteEventErr] = await this.commonService.executePromise(this.eventService.sendToArchivedEvent(archivePath, eventToDelete))
        if (deleteEventErr) {
          console.log('deleteEventErr: ', deleteEventErr);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.commonService.prepareErrorMessage(deleteEventErr),
          });
          return;
        }
        this.db.object(eventPath).remove().then(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Event deleted successfully!',
          });
        }).catch((error) => {
          console.error('Error deleting event data:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error deleting event data. Please try again later.',
          });
        });
      },
      reject: () => {},
    });

  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = inputDate.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  addEditEvent(type: string, event?: any, isShowEventForm: boolean = false) {
    if (!event) {
      event = { eventDate: this.selectedDate };
    }

    let readOnly = (type === 'readOnly');
    let data = { type: readOnly ? 'edit' : type, readOnly: readOnly, event: event };

    if (isShowEventForm) {
      this.isShowEventForm = true;
      this.currentEventData.emit(data);
    }

    this.isShowEventForm = false;
  }

  handleEditClick(event: MouseEvent, eventData: any) {
    event.stopPropagation();
    this.addEditEvent('edit', eventData, true);
  }

  async handleDeleteClick(event: MouseEvent, eventData: any) {
    event.stopPropagation();
    if (eventData && eventData.settings && eventData.settings.assets && eventData.settings.assets.length > 0) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Please delete all albums related to this events to enable deletion.',
        header: 'Alert',
        acceptIcon: 'none',
        acceptLabel: 'Ok',
        rejectVisible: false,
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
      });
    } else {
      await this.deleteEvent(eventData);
    }
  }

  selectToday() {
    // this.selectedEventList = this.events?.filter(
    //   (event) =>
    //     (event.start <= this.today && this.today <= event.end) ||
    //     isSameDay(event.start, this.today) ||
    //     (event.end && isSameDay(event.end, this.today))
    // );
    this.futureEventList = this.getFutureEvents(this.events, this.today);
    this.selectedDate = this.formatDate(this.viewDate);
  }

  selectEvent(index: number) {
    this.futureEventList.forEach((ev, i) => {
      ev.isSelected = i === index;
    });
    // this.selectedEventList.forEach((ev, i) => {
    //   ev.isSelected = i === index;
    // });
  }

  toggleFullText(event: MouseEvent, eventObj): void {
    event.stopPropagation();
    eventObj.isFullTextDisplayed = !eventObj.isFullTextDisplayed;
  }
  
  truncateDisplayName(displayName: string, isFullTextDisplayed: boolean): string {
    const maxLength = 55;
    if (displayName && displayName.length > maxLength && !isFullTextDisplayed) {
      return displayName.substring(0, maxLength) + '...';
    } else {
      return displayName;
    }
  }

  isSameDate(date1: Date, date2: Date): boolean {
    return moment(date1).isSame(date2, 'day');
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}

