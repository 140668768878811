import { Inject, Injectable, forwardRef } from "@angular/core";
import lodash from "lodash";
import { CommonServiceService } from "../common-service.service"; 
import { Role } from "../../constants/enums";
import { InheritanceService, ReplacementTags } from "../inheritance/inheritance.service"; 
import { AngularFireDatabase } from "@angular/fire/compat/database"; 
import firebase from "firebase/compat/app";

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null,
  };

  readonly dbPath = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/settings/org-preference`;
  readonly dbPathPrefPermissions = `settings/org-preference-permission`;

  constructor(
    private inheritanceProvider: InheritanceService,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun,
    private db: AngularFireDatabase
  ) {}

  // --- remove preferences of given user
  async removePreferences(role: string, userID: string, prefKey?: string) {
    // --- params verification
    if (!role || !userID)
      throw new Error("Params missing in removePreferences method call");

    // --- prepare db path
    let replacementData = ReplacementTags.prepareReplacementData(
      this.userDBNodes[role],
      userID
    );
    let dbPath = this.inheritanceProvider.prepareDBPath(
      this.dbPath,
      replacementData
    );
    if (prefKey) dbPath = `${dbPath}/${prefKey}`;

    await this.db.object(dbPath).remove();
  }

  // --- update preference data
  async updatePref(
    role: string,
    userID: string,
    prefKey: string,
    updateData: any
  ) {
    // --- params verification
    if (
      !role ||
      (role != Role.SUPERADMIN && !userID) ||
      !prefKey ||
      !updateData
    )
      throw new Error("Params missing in updatePref method call");

    // --- prepare db path
    let replacementData = ReplacementTags.prepareReplacementData(
      this.userDBNodes[role],
      userID
    );
    let dbPath = this.inheritanceProvider.prepareDBPath(
      this.dbPath,
      replacementData
    );
    dbPath = `${dbPath}/${prefKey}`;

    // --- remove extra data
    let updateObj = lodash.cloneDeep(updateData);
    delete updateObj.ownerID;
    delete updateObj.isFrom;
    delete updateObj.disable;

    // --- remove cache
    this.inheritanceProvider.removeCache(
      `${this.dbPath}${updateData.id ? `/${updateData.id}` : ""}`,
      this.userDBNodes[role],
      userID
    );

    await this.db.object(dbPath).update(updateObj);
  }

  // --- get preferences permissions list
  async getPrefsPermissions(useCache = false) {
    let prefPermissions = await this.inheritanceProvider.readFromDB(
      null,
      this.dbPathPrefPermissions,
      "list",
      null,
      useCache
    );

    return lodash.map(prefPermissions, (permData: any, key) => {
      return { ...permData, key };
    });
  }

  // --- get preferences list by inheritance
  async getPreferencesByInheritance(
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error(
        "Params missing in getPreferencesByInheritance method call"
      );

    // --- fetch preferences data by inheritance
    let prefsInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}`,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    let preferences = [];
    lodash.each(prefsInheritedData, (userPrefData) => {
      let ownerID = userPrefData.ownerID;
      let userPrefs = lodash.map(userPrefData.data, (item: any, key) => {
        return { ...item, key, ownerID };
      });

      preferences = this.commonFun.mergeTwoArrUsingKeyMatch(
        preferences,
        userPrefs
      );
    });

    // --- order preferences by key
    preferences = lodash.orderBy(preferences, "key");

    return preferences;
  }

  // --- get preference data by inheritance
  async getPreferenceByInheritance(
    role: string,
    userID: string,
    prefID: string,
    usersData?: any,
    useCache: boolean = false,
    propToReturn?: string
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID) || !prefID)
      throw new Error(
        "Params missing in getPreferenceByInheritance method call"
      );

    // --- prepare query interceptor
    const queryInterceptor = {
      uniqueIdentifier: prefID,
      execute: (dbQuery: firebase.database.Query) => {
        return dbQuery.orderByChild("id").equalTo(prefID);
      },
    };

    // --- fetch preference data by inheritance
    let prefInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}`,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache,
      queryInterceptor
    );

    // --- find highest priority non-null data from the inherited data
    let prefData: any = lodash.find(
      prefInheritedData,
      (prefData) => prefData.data != null
    );

    if (!prefData) return null;

    // --- process results
    let prefDataa = lodash
      .chain(prefData.data)
      .map((data: any, key) => {
        return { ...data, key };
      })
      .first()
      .value();

    if (lodash.chain(prefDataa).get("Override").toLower().value() == "hide")
      return null;

    if (propToReturn) return prefDataa[propToReturn];
    return { ...prefDataa, ownerID: prefData.ownerID };
  }

  // --- remove print prices cache
  removePreferencesCache(role, userID, prefID?) {
    this.inheritanceProvider.removeCache(
      `${this.dbPath}${prefID ? `/${prefID}` : ""}`,
      this.userDBNodes[role],
      userID
    );
  }
}
