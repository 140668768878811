<p-toast></p-toast>
<div class="d-flex text-center justify-content-between align-items-center flex-wrap">
  <div>
    <div class="btn-group">
      <p-button class="cmn-btn-primary " mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Previous
      </p-button>
      <div>
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <p-button class="cmn-btn-primary " mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Next
      </p-button>
      <p-button class="cmn-btn-primary outlined" (click)="selectToday()" mwlCalendarToday [(viewDate)]="viewDate">
        Today
      </p-button>
    </div>
  </div>
  <div>
    <div class="btn-group">
      <p-button class="cmn-btn-primary" (click)="setView(CalendarView.Month)"
        [class.outlined]="view !== CalendarView.Month">
        Month
      </p-button>
      <p-button class="cmn-btn-primary" (click)="setView(CalendarView.Week)"
        [class.outlined]="view !== CalendarView.Week">
        Week
      </p-button>
      <p-button class="cmn-btn-primary" (click)="setView(CalendarView.Day)"
        [class.outlined]="view !== CalendarView.Day">
        Day
      </p-button>
    </div>
  </div>
</div>
<div [ngSwitch]="view" class="mt-3">
  <mwl-calendar-month-view class="cus-mwl-calendar-month-view" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
    [events]="events" [activeDayIsOpen]="true" (dayClicked)="dayClicked.next($event.day)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>

<div class="card mt-4">
  <p-table class="cmn-table event-table" [scrollable]="true" 
  scrollHeight="77vh" 
  [responsive]="true" [value]="futureEventList">
    <ng-template pTemplate="header">
      <tr>
        <!-- <th>{{selectedDate || ''}}</th> -->
        <th>Date</th>
        <th>School</th>
        <th>Event Name</th>
        <th class="d-flex justify-content-end">
          <p-button (click)="addEditEvent('add', null, true)" class="cmn-btn-secondary btn-with-icon rounded-full" >
            <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5888_2555)">
                <path d="M6.99957 0.667969C5.74749 0.667969 4.52353 1.03925 3.48247 1.73487C2.4414 2.43049 1.62999 3.4192 1.15084 4.57597C0.671688 5.73274 0.546321 7.00562 0.790589 8.23364C1.03486 9.46166 1.63779 10.5897 2.52315 11.475C3.4085 12.3604 4.53651 12.9633 5.76453 13.2076C6.99255 13.4518 8.26543 13.3265 9.4222 12.8473C10.579 12.3682 11.5677 11.5568 12.2633 10.5157C12.9589 9.47463 13.3302 8.25067 13.3302 6.99859C13.3267 5.32067 12.6586 3.71247 11.4722 2.526C10.2857 1.33952 8.67749 0.671437 6.99957 0.667969ZM9.69895 7.43609H7.43707V9.69797C7.43707 9.814 7.39098 9.92528 7.30893 10.0073C7.22689 10.0894 7.11561 10.1355 6.99957 10.1355C6.88354 10.1355 6.77226 10.0894 6.69021 10.0073C6.60817 9.92528 6.56207 9.814 6.56207 9.69797V7.43609H4.3002C4.18417 7.43609 4.07289 7.39 3.99084 7.30795C3.90879 7.22591 3.8627 7.11463 3.8627 6.99859C3.8627 6.88256 3.90879 6.77128 3.99084 6.68923C4.07289 6.60719 4.18417 6.56109 4.3002 6.56109H6.56207V4.29922C6.56207 4.18319 6.60817 4.07191 6.69021 3.98986C6.77226 3.90781 6.88354 3.86172 6.99957 3.86172C7.11561 3.86172 7.22689 3.90781 7.30893 3.98986C7.39098 4.07191 7.43707 4.18319 7.43707 4.29922V6.56109H9.69895C9.81498 6.56109 9.92626 6.60719 10.0083 6.68923C10.0904 6.77128 10.1364 6.88256 10.1364 6.99859C10.1364 7.11463 10.0904 7.22591 10.0083 7.30795C9.92626 7.39 9.81498 7.43609 9.69895 7.43609Z" fill="#071871"/>
              </g>
              <defs>
                <clipPath id="clip0_5888_2555">
                  <rect width="14" height="14" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Add New
          </p-button>
        </th>
      </tr>
    </ng-template>
  
    <ng-template pTemplate="body" let-event let-i="rowIndex">
      <tr [ngClass]="{'selected' : event.isSelected}" (click)="selectEvent(i); addEditEvent('readOnly', event, true)">
        <td>
          {{ (event?.start | date: 'MMMM d, y') || '-' }}
          <span *ngIf="event?.end && !isSameDate(event.start, event.end)">
            - {{ event.end | date: 'MMMM d, y' }}
          </span>
        </td>
        <td>{{ ( event?.orgId ? getOrgByOrgId(event.orgId) : '-' ) || '-' }}</td>
        <td>
          <p class="text-ellipsis word-wrap max-w-200px" (click)="toggleFullText($event, event)">
            {{ truncateDisplayName(event?.meta?.displayName, event.isFullTextDisplayed) }}
          </p>
        </td>
        <td>
          <div class="d-flex gap-16px justify-content-end">
            <!-- <p-button (click)="handleEditClick($event, event)" class="cmn-btn-secondary" >
              <img src="../../../../assets/svg/edit-icon.svg" alt="">
              Edit
            </p-button> -->
            <!-- <p-button class="cmn-btn-secondary">
              <img class="w-h-20" src="../../../../assets/svg/clone-icon.svg" alt="">
              Clone
            </p-button> -->
            <p-button (click)="handleDeleteClick($event, event)" class="cmn-btn-secondary">
              <img src="../../../../assets/svg/bin-icon.svg" alt="">
            </p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="tr-h-50px">
        <td></td>
        <td></td>
        <td>
          <div class="text-center">
             No events found!
          </div>
        </td>
        <td></td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div #target></div>