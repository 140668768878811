import { Injectable, Inject, forwardRef } from "@angular/core";
import { UserService } from "../user/user.service";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { AngularFireUploadTask } from "@angular/fire/compat/storage";
import { HttpClient } from '@angular/common/http';
import lodash from "lodash";
import { map, throttleTime } from 'rxjs/operators';
import { TeammateService } from "../teammateService/teammate.service";
import { Role } from "../../constants/enums";
import moment from "moment";
import { CommonServiceService } from "../common-service.service"; 
import { CacheKeys, CacheService } from "../cache/cache.service";
// import { PreferencesProvider } from "../../providers/preferences/preferences";
import { from } from "rxjs";
import { InheritanceService, ReplacementTags } from "../inheritance/inheritance.service";
import { environment } from "../../../../environments/environment.stage";
import { PreferencesService } from "../preferences/preferences.service";
import { getAuth, signOut } from "firebase/auth";
import { Router } from "@angular/router";


export class Script {
  public situation: string;
  public script: string;
  public _key: string;

  constructor(situation?: string, script?: string) {
    this.situation = situation;
    this.script = script;
  }

  clearInternal() {
    this._key = null;

    lodash.forOwn(this, (value, key) => {
      if (this[key] === undefined) {
        this[key] = null;
      }
    });
  }
}

export class PanicAction {
  public name: string;
  public title: string;
  public phone: string;
  public email: string;

  public sendSMS: boolean;
  public sendEmail: boolean;
  public sendByPhone: boolean;

  public _key: string;

  constructor(name?: string) {
    this.name = name;
  }

  parse(val: any) {
    lodash.forOwn(val, (value, key) => {
      this[key] = value;
    });
  }

  clearInternal() {
    this._key = null;

    lodash.forOwn(this, (value, key) => {
      if (this[key] === undefined) {
        this[key] = null;
      }
    });
  }
}


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public studioLogoUrl: string;
  public orgLogoUrl: string;

  public static readonly kioskScannerTypes = [
    {
      id: "none",
      displayName: "None",
    },
    {
      id: "x9700m",
      displayName: "X9700M",
    },
    {
      id: "zebra_csv",
      displayName: "Zebra CSV",
    },
    {
      id: "ms3392",
      displayName: "MS3392",
    },
  ];

  constructor(
    @Inject(forwardRef(() => UserService)) public user,
    @Inject(forwardRef(() => TeammateService)) public teammateService,
    // @Inject(forwardRef(() => AdmissibeUtil)) public admissibeUtil,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun,
    @Inject(forwardRef(() => PreferencesService)) public preferencesProvider,
    @Inject(forwardRef(() => CacheService)) public cacheProvider,
    @Inject(forwardRef(() => InheritanceService)) public inheritanceProvider,
    public db: AngularFireDatabase,
    private fireStorage: AngularFireStorage,
    public http: HttpClient,
    private router: Router
  ) { }


  setStudioData() {
    // --- monitor for app changes
    this.user &&
      this.user.role == "admin" &&
      this.user.studioID &&
      this.getOrgManagerTypes(this.user.studioID)
        .then((res: any) => {
          UserService.orgManagerType = res;
          this.getStudioLogo(this.user.studioID, (url) => {
            console.log('url: ', url);
            // this.studioLogo = url;
          });
        });
    this.user.role == Role.STUDIO &&
      this.getStudioLogo(this.user.studioID, (url) => {
        // this.studioLogo = url;
        console.log('url: ', url);
      });
    this.monitorOrgManagerUpdate();

    // --- as soon as we have an authenticated user boot the DB by getting the active organisation
    this.getTemplate(
      async (org) => {
        // --- if current user is studio, set studio data
        if (
          this.user.role == Role.STUDIO &&
          (!this.user.studio ||
            (this.user.studio && this.user.studio.key != this.user.studioID))
        ) {
          let studioData = await this.commonFun.getStudio(this.user.studioID);
          this.user.setStudioData(studioData);
          // this.studioName = studioData.studioName;
          console.log('studioData.studioName: ', studioData.studioName);
        }

      },
      () => {
        // no permission redirect to login page
        signOut(getAuth()).then((res) =>{
          this.router.navigate(['/auth'])
        })
      }
    );

    if (this.user.orgID) {
      let sub = this.db
        .object(`organizations/${this.user.orgID}`)
        .valueChanges()
        .subscribe((res) => {
          sub.unsubscribe();
          if (res) {
            let studioID = res["studioID"];
            this.getStudioLogo(studioID, (url) => {
              // this.studioLogo = url;
              console.log('url: ', url);
            });
          }
        });
    }
  }

  monitorOrgManagerUpdate() {
    if (this.user.role == "admin") {
      this.user.monitorOrgManagerUpdate(this.user.orgID, (studioId) => {
        if (this.user.studioID !== studioId) {
          this.user.studioID = studioId;
          this.getOrgManagerTypes(studioId).then((res: any) => {
            UserService.orgManagerType = res;
            this.getStudioLogo(studioId, (url) => {
              console.log('url: ', url);
              // this.studioLogo = url;
            });
          });
        }
      });
    }
  }


  getSuperAdminPrintSetup() {
    return this.db.object(`/settings/orderPrintSetup/defaultPrint`).valueChanges();
  }
  getSuperAdminPriceSetup() {
    return this.db.object(`/settings/orderPrintSetup/defaultPrice`).valueChanges();
  }
  checkDates(printSetupObj) {
    Object.keys(printSetupObj).map(key => {
      if (key == 'bulkDiscountStartDate' && printSetupObj[key]) {
        printSetupObj[key] = this.formateDate(printSetupObj[key]);
      }
      if (key == 'bulkDiscountEndDate' && printSetupObj[key]) {
        printSetupObj[key] = this.formateDate(printSetupObj[key]);
      }
    })
    return printSetupObj;
  }

  readonly superadminPrintQueue = { id: Role.SUPERADMIN, name: "High5.ID" }
  readonly dbPathStudiosName = `studios/${ReplacementTags.USER_ID}/studioName`
  async getAvailablePrintQueues(
    role,
    userID,
    usersData,
    useCache = false
  ) {
    if(!role || (role != Role.SUPERADMIN && !userID))
      throw new Error("Params missing in getAvailablePrintQueues call!");
    
    if(role == Role.ORG) {
      let orgData = await this.commonFun.getOrgData(userID, useCache)
      role = Role.STUDIO;
      userID = lodash.get(orgData, 'studioID')
    }

    let printQueues = [this.superadminPrintQueue];

    if(role == Role.SUPERADMIN)
      return printQueues;
    
    if(role == Role.STUDIO) {
      // --- fetch parents list
      let studiosNamesInheritedData = await this.inheritanceProvider.getByStudioInheritance(
        userID,
        this.dbPathStudiosName,
        "object",
        usersData,
        null,
        useCache
      )

      lodash.eachRight(studiosNamesInheritedData, (item: any) => {
        if(item && item.data && item.ownerID) {
          printQueues.push({ id: item.ownerID, name: item.data })
        }
      })
    }

    return printQueues;
  }

  formateDate(date) {
    if (date) {
      return moment(parseInt(date)).format("YYYY/MM/DD")
    } else{
      return null;
    }
  }
  mergeTeamManagerAndSuperAdmin(tManaer, sAdmin) {
    Object.keys(sAdmin).map(key => {
      if (!tManaer[key]) {
        if (key == 'rpCardPrice') {
          tManaer[key] = sAdmin['rpCardTeammatePrice'] ? sAdmin['rpCardTeammatePrice'] : '';
        } else if (key == 'rpCardShippingPrice') {
          tManaer[key] = sAdmin['rpCardTeammateShippingPrice'] ? sAdmin['rpCardTeammateShippingPrice'] : '';
        } else if (key == 'tesLin' || key == 'pvc') {
          tManaer[key] = {};
          Object.keys(sAdmin[key]).map(innrKey => {
            if (innrKey == 'bulkCardPrice') {
              tManaer[key][innrKey] = sAdmin[key]['bulkCardTeammatePrice'] ? sAdmin[key]['bulkCardTeammatePrice'] : '';
            } else if (innrKey == 'bulkCardShippingPrice') {
              tManaer[key][innrKey] = sAdmin[key]['bulkCardTeammateShippingPrice'] ? sAdmin[key]['bulkCardTeammateShippingPrice'] : '';
            } else if (innrKey == 'bulkCardDiscountPrice') {
              tManaer[key][innrKey] = sAdmin[key]['bulkCardDiscountTeammatePrice'] ? sAdmin[key]['bulkCardDiscountTeammatePrice'] : '';
            } else {
              tManaer[key][innrKey] = sAdmin[key][innrKey]
            }
          })
        } else {
          tManaer[key] = sAdmin[key];
        }
      } else if (key == 'tesLin' || key == 'pvc') {
        Object.keys(sAdmin[key]).map(innrKey => {
          if (!tManaer[key][innrKey]) {
            if (innrKey == 'bulkCardPrice') {
              tManaer[key][innrKey] = sAdmin[key]['bulkCardTeammatePrice'] ? sAdmin[key]['bulkCardTeammatePrice'] : '';
            } else if (innrKey == 'bulkCardShippingPrice') {
              tManaer[key][innrKey] = sAdmin[key]['bulkCardTeammateShippingPrice'] ? sAdmin[key]['bulkCardTeammateShippingPrice'] : '';
            } else if (innrKey == 'bulkCardDiscountPrice') {
              tManaer[key][innrKey] = sAdmin[key]['bulkCardDiscountTeammatePrice'] ? sAdmin[key]['bulkCardDiscountTeammatePrice'] : '';
            } else {
              tManaer[key][innrKey] = sAdmin[key][innrKey]
            }
          }
        })
      }
    })
    return tManaer;
  }

  setFormValues(obj, rootData, isStudio?) {
    let formObj = {}, printObj = {};
    if (isStudio) {
      formObj = { ...obj }
    } else {
      formObj = {
        ...obj,
        rpCardPrice: obj.rpCardTeammatePrice ? obj.rpCardTeammatePrice : '',
        rpCardShippingPrice: obj.rpCardTeammateShippingPrice ? obj.rpCardTeammateShippingPrice : '',
        pvc: {
          bulkCardPrice: obj.pvc.bulkCardTeammatePrice ? obj.pvc.bulkCardTeammatePrice : '',
          bulkCardShippingPrice: obj.pvc.bulkCardTeammateShippingPrice ? obj.pvc.bulkCardTeammateShippingPrice : '',
          bulkCardDiscountPrice: obj.pvc.bulkCardDiscountTeammatePrice ? obj.pvc.bulkCardDiscountTeammatePrice : ''
        },
        tesLin: {
          bulkCardPrice: obj.tesLin.bulkCardTeammatePrice ? obj.tesLin.bulkCardTeammatePrice : '',
          bulkCardShippingPrice: obj.tesLin.bulkCardTeammateShippingPrice ? obj.tesLin.bulkCardTeammateShippingPrice : '',
          bulkCardDiscountPrice: obj.tesLin.bulkCardDiscountTeammatePrice ? obj.tesLin.bulkCardDiscountTeammatePrice : ''
        }
      }
    }
    printObj = { ...obj };
    return { printObj: printObj, formObj: formObj, rootData: rootData }
  }

  monitorAppVersion(callback: Function) {
    let subscribe = this.db
      .object("/settings/appVersion")
      .valueChanges()
      .subscribe((value: any) => {
        if (lodash.isFunction(callback)) {
          callback(value);
        }
      });
  }

  getDefaults(callback: Function) {
    let subscribe = this.db
      .object("/settings/defaults/")
      .valueChanges()
      .subscribe((value: any) => {
        subscribe.unsubscribe();

        if (lodash.isFunction(callback)) {
          callback(value);
        }
      });
  }

  getOrg(orgID: string, callback: any) {
    let subscribe = this.db
      .object("organizations/" + orgID)
      .snapshotChanges()
      .subscribe((entry: any) => {
        subscribe.unsubscribe();

        if (entry) {
          let value = entry.payload.val();
          value._key = entry.key;

          if (lodash.isFunction(callback)) {
            callback(value);
          }
        }
      });
  }

  getTemplate(callback?: any, callbackError?: any) {
    return new Promise((resolve, reject) => {
      if (this.user.orgID === undefined) {
        if (lodash.isFunction(callback)) {
          callback();
        }
        resolve(false);
      }

      if (this.user.orgID) {
        let subscribe = this.db
          .object("organizations/" + this.user.orgID)
          .snapshotChanges()
          .subscribe({
            next: (entry: any) => {
              subscribe.unsubscribe();

              if (entry && entry.key) {
                let value = entry.payload.val();
                value._key = entry.key;
                value.key = entry.key;

                this.user.org = value;

                if (lodash.isFunction(callback)) {
                  callback(value);
                }
                resolve(value);
                return;
              } else {
                if (lodash.isFunction(callback)) {
                  callback();
                }
                resolve('');
                return;
              }
            },
            error: () => {
              if (lodash.isFunction(callbackError)) {
                callbackError();
              }
              return;
            }
          });
      }
    });
  }
  getTemplateByOrgId(orgID, callback?: any, callbackError?: any) {
    return new Promise(resolve => {
      if (orgID === undefined) {
        if (lodash.isFunction(callback)) {
          callback();
        }
         resolve(false);
      }

      let subscribe = this.db
        .object("organizations/" + orgID)
        .snapshotChanges()
        .subscribe(
          (entry: any) => {
            subscribe.unsubscribe();

            if (entry && entry.key) {
              let value = entry.payload.val();
              value._key = entry.key;

              this.user.org = value;

              if (lodash.isFunction(callback)) {
                callback(value);
              }
              resolve(value);
              return;
            } else {
              if (lodash.isFunction(callback)) {
                callback();
              }
              resolve('');
              return;
            }
          },
          () => {
            if (lodash.isFunction(callbackError)) {
              callbackError();
            }
          }
        );
    });
  }

  // --- monitor locations
  monitorLocations(orgID: string) {
    return from(this.db.list(`organizations/${orgID}/settings/locations`).snapshotChanges())
      .pipe(
        throttleTime(500, undefined, { leading: true, trailing: true }),
        map((locationsData: any) => {
          let locations = lodash.map(locationsData, (snapshot: any) => {
            return { value: snapshot.payload.val(), key: snapshot.payload.key }
          })
          return lodash.sortBy(locations ,[(location) => location.value]);
        })
      )
  }

  addLocation(locationName: string) {
    this.db
      .list("/organizations/" + this.user.orgID + "/settings/locations/")
      .push(locationName);
  }




  addOrgPreference(data: any) {
    return new Promise((resolve, reject) => {
      try {
        data.timestamp = new Date().getTime();
        this.db
          .list("/settings/org-preference/")
          .push(data);
        resolve(true);
      } catch (e) {
        resolve(true)
      }

    })

  }
  addOrgPreferencePermissionObj(data: any) {
    return new Promise((resolve, reject) => {
      try {
        data.timestamp = new Date().getTime();
        this.db
          .list("/settings/org-preference-permission/")
          .push(data);
        resolve(true);
      } catch (e) {
        resolve(true)
      }
    })
  }
  getPreferencePermissions(id?) {
    return this.db
      .list('/settings/org-preference-permission/').snapshotChanges().pipe(
        map(changes =>
          changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
  }
  editPreferencePermissions(id: string, data: any) {
    data.lastUpdate = new Date().getTime();
    data.Override = 'edit';
    return this.db.list("/settings/org-preference-permission/").update(id, data)
  }
  addAdmissibilGrpTeammate(tag, data: any, user) {
    data.timestamp = new Date().getTime();
    data.Override = 'add';
    return this.db.list("/studios/" + user + tag).push(data)
  }
  addAdmissibilGrpTeammatDB(data, id, tag) {
    return this.db.object("/studios/" + id + tag).update(data)
  }
  addAdmissibilGrpOrgOver(tag, data: any, id, over) {
    data.timestamp = new Date().getTime();
    data.Override = over
    return this.db.list("/organizations/" + id + tag).push(data)
  }
  addAdmissibilGrpOrgDB(tag, data, id) {
    return this.db.object("/organizations/" + id + tag).update(data)
  }
  addAdmissibilGrpOrg(tag, data: any, user) {
    console.log('data: ', data);
    data.timestamp = new Date().getTime();
    data.Override = 'add';
    return this.db.list("/organizations/" + user + tag + '/').push(data)
  }
  getEditOrg(id, userID, tag) {
    return this.db.object("/organizations/" + userID + tag + id).valueChanges()
  }
  getEditTeammate(id, userID, tag) {
    return this.db.object("/studios/" + userID + tag + id).valueChanges()
  }
  removeAdmissibilGrpOrg(tag, id, user) {
    return this.db.object("/organizations/" + user + tag + id).remove()
  }
  removeAdmissibilGrpTeammate(id, user, tag) {
    return this.db.object("/studios/" + user + tag + id).remove()
  }
  addAdmissibilGrpTeammateOver(tag, data: any, id, over) {
    data.timestamp = new Date().getTime();
    data.Override = over
    return this.db.list("/studios/" + id + tag).push(data)
  }

  /**
   * @deprecated use method from preference provider instead
   */
  getPreference(id) {
    if (id != '') {
      return this.db.object('/settings/org-preference/' + id).valueChanges()
    } else {
      return this.db
        .list('/settings/org-preference/').snapshotChanges().pipe(
          map(changes =>
            changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
          )
        )
    }
  }

  /**
   * @deprecated use method from preference provider instead
   */
  updatePreference(data: any, role?, id?, key?) {
    if (data["value"] != undefined) {
      let url = '/settings/org-preference/';
      if (role == Role.STUDIO) {
        url = `/studios/${id}/settings/org-preference/`
      } else if (role == 'admin') {
        url = `/organizations/${id}/settings/org-preference/`
      }
      url = url + key
      return this.db.object(url).set(data)
    }else {
      return null;
    }
  }

  editPreference(data: any, role?, id?) {
    let url = '/settings/org-preference/';
    if (role == Role.STUDIO) {
      url = `/studios/${id}/settings/org-preference/`
    } else if (role == 'admin') {
      url = `/organizations/${id}/settings/org-preference/`
    }
    let commanObj = { 'true': true, '1': 1, '0': 0, '2': 2, 'false': false }
    let edit: any = [];
    let final: any = {};
    edit = lodash.cloneDeep(data);
    if (edit && edit.length != 0) {
      edit.map((val) => {
        let key = val.key;
        delete val["key"];
        let value = commanObj[val.value];
        if (value !== undefined) {
          val.value = value;
        }
        val.lastUpdate = new Date().getTime();
        if (key != undefined) {
          final[key] = lodash.cloneDeep(val);
        }
      })
      return this.db.object(url).set(final)
    } else {
      return this.db.object(url).set(final)
    }
  }

  removeLocation(locationKey: string) {
    this.db
      .list("/organizations/" + this.user.orgID + "/settings/locations/")
      .remove(locationKey);
  }
  admissibilGrpTeamDbPresent(id, tag) {

    return this.db.list("/studios/" + id + '/settings/org-preference/').snapshotChanges().pipe(
      map(changes =>
        changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
  }
  admissibilGrpOrgDbPresent(id, tag) {
    return this.db.list("/organizations/" + id + '/settings/org-preference/').snapshotChanges().pipe(
      map(changes =>
        changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
  }

  isTeammateCopy(id, userID, tag) {
    return this.db.object("/studios/" + userID + tag + id).valueChanges()
  }
  isOrgnizationCopy(id, userID, tag) {
    return this.db.object("/organizations/" + userID + tag + id).valueChanges()
  }

  DeleteOrgCopy(id, userID, tag) {
    return this.db.object("/organizations/" + userID + tag + id).remove()
  }
  DeleteTeammateCopy(id, userID, tag) {
    return this.db.object("/studios/" + userID + tag + id).remove()
  }
  editAdmissibilGrp(data: any, id: string) {
    data.lastUpdate = new Date().getTime();
    return this.db.list('/settings/org-preference/').update(id, data)
  }
  editAdmissibilGrpOrg(userID, data: any, id: string, tag) {
    data.lastUpdate = new Date().getTime();
    data.Override = 'edit';
    return this.db.list("/organizations/" + userID + tag).update(id, data)
  }
  editAdmissibilGrpTemmate(userID, data: any, id: string, tag) {
    data.lastUpdate = new Date().getTime();
    data.Override = 'edit';
    return this.db.list("/studios/" + userID + tag).update(id, data)
  }
  editAdmissibilGrpOrgOver(userID, data: any, id: string, over: string, tag) {
    if (data) {
      data.Override = over;
      data.lastUpdate = new Date().getTime();
    }
    return this.db.list("/organizations/" + userID + tag).set(id, data)
  }

  // --- get kiosks data path
  getKiosksPath(orgID: string, kioskID?: string) {
    return `kiosks/${orgID}/regular/${kioskID || ''}`;
  }
  
  // --- add kiosk
  async addKiosk(orgID: string, kioskData: any) {
    if(!orgID || !kioskData)
      throw new Error("Params missing!")

    let path = this.getKiosksPath(orgID)
    let theneableRef = this.db.list(path).push(kioskData)
    await theneableRef;

    return theneableRef.key;
  }

  // --- remove kiosk
  async removeKiosk(orgID: string, kioskID: string) {
    if(!orgID || !kioskID)
      throw new Error("Params missing!")

    let path = this.getKiosksPath(orgID, kioskID)
    await this.db.object(path).remove();
    
    return true;
  }

  // --- update kiosk
  async updateKiosk(orgID: string, kioskID: string, updateObj: any) {
    if(!orgID || !kioskID || !updateObj)
      throw new Error("Params missing!")

    let path = this.getKiosksPath(orgID, kioskID)
    await this.db.object(path).update(updateObj);
    
    return true;
  }

  // --- monitor kiosks
  monitorKiosks(orgID: string, returnType: "obj" | "list" = "list") {
    if(!orgID) throw new Error("Params missing!");

    let path = this.getKiosksPath(orgID)
    return from(this.db.list(path).snapshotChanges())
      .pipe(
        throttleTime(500, undefined, { leading: true, trailing: true }),
        map((kiosksData: any) => {
          let kiosks = lodash.map(kiosksData, (snapshot: any) => {
            return { ...snapshot.payload.val(), key: snapshot.payload.key  }
          })
          if(returnType == "list") return kiosks
          else return lodash
            .chain(kiosks)
            .keyBy("key")
            .mapValues(value => lodash.omit(value, "key"))
            .value()
        })
      )
  }

  // --- monitor kiosk
  monitorKiosk(orgID: string, kioskID: string) {
    if(!orgID || !kioskID) throw new Error("Params missing!");

    let path = this.getKiosksPath(orgID, kioskID)
    return from(this.db.object(path).valueChanges())
      .pipe(
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
  }

  // --- get studio ID for given org ID
  async getStudioID(orgID: string, useCache = false) {
    if(!orgID) return null;

    if(useCache) {
      let cachedData = this.cacheProvider.get(CacheKeys.OrgStudioID, orgID)
      if(cachedData) return cachedData.value;
    }
    
    let dbPath = `organizations/${orgID}/studioID`
    let studioID = (await this.db.object(dbPath).query.once('value')).val()

    // --- set cache
    this.cacheProvider.set(CacheKeys.OrgStudioID, orgID, studioID)

    return studioID;
  }

  /**
   * Read preference by inheritance
   */
  prefBasePath = 'settings/org-preference'
  async getPrefValue(orgId: string, prefId: string, studioId?: string, propToReturn?, useCache = false) {
    const parsePrefObj = (prefObj) => {
      let formattedPrefObj = {};
      lodash.forOwn(prefObj, (val, key) => {
        formattedPrefObj = val;
        formattedPrefObj['key'] = key;
      })

      if (propToReturn) return formattedPrefObj[propToReturn];
      return formattedPrefObj;
    }

    // --- check for org preference
    if (orgId) {
      let orgPref;  
      if(useCache) {
        let cachedData = this.cacheProvider.get(CacheKeys.PreferenceData, `${orgId}.${prefId}`)
        if(cachedData) orgPref = cachedData.value
      }

      if(!orgPref) {
        orgPref = (await this.db.object(`organizations/${orgId}/${this.prefBasePath}`).query
          .orderByChild('id').equalTo(prefId).once('value')).val();
        
        // --- set cache
        this.cacheProvider.set(CacheKeys.DBValueFromPath, `${orgId}.${prefId}`, orgPref)
      }
      
      if (orgPref && orgPref.Override != 'hide') {
        return parsePrefObj(orgPref);
      }

      // --- fetch org studioID if not given in param
      if(!studioId) {
        studioId = await this.getStudioID(orgId, useCache)
      }
    }

    // --- check for studio preference
    if (studioId) {
      let studioPref;
      
      if(useCache) {
        let cachedData = this.cacheProvider.get(CacheKeys.PreferenceData, `${studioId}.${prefId}`)
        if(cachedData) studioPref = cachedData.value
      }

      if(!studioPref) {
        studioPref = (await this.db
          .object(`studios/${studioId}/${this.prefBasePath}`)
          .query.orderByChild('id').equalTo(prefId).once('value')).val();
        
        // --- set cache
        this.cacheProvider.set(CacheKeys.DBValueFromPath, `${studioId}.${prefId}`, studioPref)
      }
      
      if (studioPref && studioPref.Override != 'hide') {
        return parsePrefObj(studioPref);
      }
    }

    // --- check for superadmin preference
    let superadminPref;
    if(useCache) {
      let cachedData = this.cacheProvider.get(CacheKeys.PreferenceData, `${prefId}`)
      if(cachedData) superadminPref = cachedData.value
    }

    if(!superadminPref) {
      superadminPref = (await this.db
        .object(`${this.prefBasePath}`)
        .query.orderByChild('id').equalTo(prefId).once('value')).val();
        
      // --- set cache
      this.cacheProvider.set(CacheKeys.DBValueFromPath, `${prefId}`, superadminPref)
    }
    
    if (superadminPref && superadminPref.Override != 'hide') {
      return parsePrefObj(superadminPref);
    }

    return null;
  }

  async getTaxExeptValue(){
    return new Promise((resolve, reject)=>{
      try{
        this.getOrgData(this.user.orgID).then((res:any)=>{
          // resolve(res);
          if(res && res.hasOwnProperty('taxExempt')) {
            resolve(res.taxExempt)
          }else {
            resolve(true);
          }
        })
      }catch(e){
        resolve(null);
      }
    })
  }

  regx = {
    '': new RegExp(/^(?=.*[!@#$%^&*])/)
  }

  async getPasswordErrorLables() {
    let errorMsg = {
      'atLeastOneNo': 'Must include at least one number',
      'atLeastOneSpecialChar': 'Must include at least one special character',
      'oneNoOneChar': 'Must include at least one number and at least one special character',
      '1': 'Must include at least one number and at least one special character'
    }
    let value: any = await this.preferencesProvider.getPreferenceByInheritance(
      this.user.role,
      this.commonFun.getUserID(this.user),
      "adminPasswordPolicyMaximumPasswordComplexityRequirements",
      this.commonFun.getUserData(this.user),
      false,
      'value'
    );
    return errorMsg[value];
  }

  async adminPasswordValidation(password: string, checkPasswordPolicyPrefValue: string, minimumLength, maximumLength) {
    return new Promise(async (resolve, reject) => {
      try {
        let atLeastOneSpecialChar: RegExp = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{5,}$/);
        let atLeastOneNo: RegExp = new RegExp(/^(?=(.*[\d]){1,})/);
        let oneNoOneChar: RegExp = new RegExp(/^(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{5,}$/);
        let passwordComplexity = checkPasswordPolicyPrefValue;

        maximumLength = maximumLength < 6 ? 20 : maximumLength;
        minimumLength = minimumLength < 6 ? 6 : minimumLength;
        if (password.valueOf === null || password.valueOf === undefined) {
          resolve(`[] Your password must be between ${minimumLength} and ${maximumLength} characters long.<br/><br/>`)
        }
        if (password.length < parseInt(minimumLength) || password.length > parseInt(maximumLength)) {
          resolve(`[] Your password must be between ${minimumLength} and ${maximumLength} characters long.<br/><br/>`)
        }
        if (password.length >= parseInt(minimumLength) && password.length <= parseInt(maximumLength)) {
          if (passwordComplexity == 'atLeastOneSpecialChar') {
            resolve(!atLeastOneSpecialChar.test(password) ? "[] Your password must include at least one special character.<br/><br/>" : '')
          }
          if (passwordComplexity == 'atLeastOneNo') {
            resolve(!atLeastOneNo.test(password)? "[] Your password must include at least one number.<br/><br/>" : '');
          }
          if (passwordComplexity == 'oneNoOneChar' || passwordComplexity == '1') {
            resolve(!oneNoOneChar.test(password)? "[] Your password must include at least one number and one special character.<br/><br/>" : "")
          }
        } else {
          resolve('')
        }
      } catch {
        resolve('');
      }
    })
  }

  monitorVisuals(callback: Function) {
    let subscription = this.db
      .object("/organizations/" + this.user.orgID + "/settings/visuals/")
      .valueChanges()
      .subscribe(obj => {
        callback(obj);
      });
  }

  monitorYearbookSetup(callback) {
    let subscription = this.db
      .object("/organizations/" + this.user.orgID + '/settings/yearbook-setup/')
      .valueChanges()
      .subscribe(obj => {
        callback(obj);
      });
  }
  saveVisualsLogo(file: any, callback: Function) {
    let orgLogoRef = "photos/organisations/" + this.user.orgID + "/orgLogo";

    // save photo
    const imageRef = this.fireStorage.ref(orgLogoRef);

    let task: AngularFireUploadTask;
    task = imageRef.put(file);

    task
      .then(() => {
        imageRef
          .getDownloadURL()
          .toPromise()
          .then(url => {
            callback(orgLogoRef, url);
          })
          .catch(() => {
            callback();
          });
      })
      .catch(() => {
        callback();
      });
  }

  getVisualsLogo(callback: Function, overrideOrgID?: string) {
    let orgID;
    if (overrideOrgID) {
      orgID = overrideOrgID;
    } else {
      orgID = this.user.orgID;
    }

    if (this.orgLogoUrl) {
      callback(this.orgLogoUrl);
      return;
    }

    let orgLogoRef = "photos/organisations/" + orgID + "/orgLogo";
    this.getOrg(orgID, orgData => {
      if(orgData && orgData.settings && orgData.settings.visuals && orgData.settings.visuals.logo) {
        const imageRef = this.fireStorage.ref(orgLogoRef);
        imageRef.getDownloadURL().toPromise().then(url => {
          this.orgLogoUrl = url;
          callback(url);
        }).catch(e => {
          callback();
        }); 
      } else {
        callback();
      }
    })
  }

  getOrgLogo(orgData: any): string {
    if(orgData && orgData.settings && orgData.settings.visuals && orgData.settings.visuals.logo) {
      if (orgData.settings.visuals.logo.indexOf('http') > -1 || orgData.settings.visuals.logo.indexOf('firebasestorage') > -1) {
        return orgData.settings.visuals.logo
      } else {
        return `${environment.firebaseImgUrl}${environment.firebaseConfig.storageBucket}/o/photos%2Forganisations%2F${orgData.key}%2ForgLogo?alt=media`
      } 
    } else {
      return '';
    }
  }

  saveVisuals(form: any): Promise<void> {
    return this.db
      .object("/organizations/" + this.user.orgID + "/settings/visuals/")
      .update(form);
  }

  // --- org events
  broadcastOrgEvent(eventName: string, eventParams: any): Promise<void> {
    return this.db
      .object("/organizations/" + this.user.orgID + "/events/" + eventName)
      .set(eventParams);
  }

  subscribeOrgEvents(callback: Function) {
    let subscription = this.db
      .object("/organizations/" + this.user.orgID + "/events/")
      .valueChanges()
      .subscribe(obj => {
        callback(obj);
      });
  }

  // --- settings scripts
  monitorScripts(callback: Function) {
    let subscription = this.db
      .list("/organizations/" + this.user.orgID + "/settings/scripts/")
      .snapshotChanges()
      .subscribe(obj => {
        let list = [];

        lodash.each(obj, entry => {
          let value: any = {
            key: entry.key,
            value: entry.payload.val()
          };

          let script = new Script(value.value.situation, value.value.script);
          script._key = value.key;

          list.push(script);
        });

        callback(list);
      });
  }

  addScript(script: Script) {
    script.clearInternal();

    script.script = script.script.replace(/\r\n|\r|\n/g, "<br />");

    this.db
      .list("/organizations/" + this.user.orgID + "/settings/scripts/")
      .push(script);
  }

  removeScript(scriptKey: string) {
    this.db
      .list("/organizations/" + this.user.orgID + "/settings/scripts/")
      .remove(scriptKey);
  }

  // --- settings panic actions
  monitorPanicActions(monitor: boolean, callback: Function) {
    let subscription = this.db
      .list("/organizations/" + this.user.orgID + "/settings/panicActions/list")
      .snapshotChanges()
      .subscribe(obj => {
        if (subscription && monitor === false) {
          subscription.unsubscribe();
        }

        let list = [];

        lodash.each(obj, entry => {
          let value = {
            key: entry.key,
            value: entry.payload.val()
          };

          let action = new PanicAction();
          action._key = value.key;
          action.parse(value.value);

          list.push(action);
        });

        callback(list);
      });
  }

  addPanicAction(action: PanicAction) {
    action.clearInternal();

    this.db
      .list("/organizations/" + this.user.orgID + "/settings/panicActions/list")
      .push(action);
  }

  updatePanicAction(action: PanicAction, key: string) {
    let actionValue = lodash.cloneDeep(action);
    actionValue.clearInternal();

    this.db
      .object(
        "/organizations/" +
        this.user.orgID +
        "/settings/panicActions/list/" +
        key
      )
      .set(actionValue);
  }

  removePanicAction(actionKey: string) {
    this.db
      .list("/organizations/" + this.user.orgID + "/settings/panicActions/list")
      .remove(actionKey);
  }

  updatePanicButtonMessage(phone: boolean, message: string) {
    let phoneStr = phone === true ? "Phone" : "";

    this.db
      .object(
        "/organizations/" +
        this.user.orgID +
        "/settings/panicActions/message" +
        phoneStr
      )
      .set(message);
  }


  saveStudioLogo(studioID: any, file: any) {
    return new Promise(resolve => {
      let studioLogoRef = "photos/studios/" + studioID + "/studioLogo";

      this.db.object("/studios/" + studioID + "/studioLogo/").set(studioLogoRef);

      // save photo
      this.fireStorage.ref(studioLogoRef).getDownloadURL().toPromise().then(res => {
        if (!file.includes("firebasestorage.googleapis.com")) {
          const pic = this.fireStorage.ref("photos/studios/" + studioID + "/studioLogo");
          pic.putString(file, "data_url").then(res => {
            resolve("success");
          }, (err) => {
            resolve(err);
          }).catch(e => {
            resolve(e);
          });
        } else {
          resolve("success");
        }
      }, err => {
        if (!file.includes("firebasestorage.googleapis.com")) {
          const pic = this.fireStorage.ref("photos/studios/" + studioID + "/studioLogo");
          pic.putString(file, "data_url").then(res => {
            resolve("success");
          }, (err) => {
            resolve(err);
          }).catch(e => {
            resolve(e);
          });
        } else {
          resolve("success");
        }
      }).catch(e => {
        resolve(e);
      })
    });
  }

  savePortalDistrictLogo(studioID: any, file: any) {
    return new Promise(resolve => {
      let studioLogoRef = "photos/portal-district/" + studioID + "/studioLogo";

      this.db.object("/studios/" + studioID + "/studioLogo/").set(studioLogoRef);

      // save photo
      this.fireStorage.ref(studioLogoRef).getDownloadURL().toPromise().then(res => {
        if (!file.includes("firebasestorage.googleapis.com")) {
          const pic = this.fireStorage.ref("photos/portal-district/" + studioID + "/studioLogo");
          pic.putString(file, "data_url").then(res => {
            resolve("success");
          }, (err) => {
            resolve(err);
          }).catch(e => {
            resolve(e);
          });
        } else {
          resolve("success");
        }
      }, err => {
        if (!file.includes("firebasestorage.googleapis.com")) {
          const pic = this.fireStorage.ref("photos/portal-district/" + studioID + "/studioLogo");
          pic.putString(file, "data_url").then(res => {
            resolve("success");
          }, (err) => {
            resolve(err);
          }).catch(e => {
            resolve(e);
          });
        } else {
          resolve("success");
        }
      }).catch(e => {
        resolve(e);
      })
    });
  }

  getStudioLogo(studioID: string, callback: Function) {
    if (!this.user.studioID) {
      callback();
      return;
    }

    let studioLogoRef = "photos/studios/" + studioID + "/studioLogo";

    const imageRef = this.fireStorage.ref(studioLogoRef);

    const subscription = imageRef
      .getDownloadURL()
      .toPromise()
      .then(url => {
        this.studioLogoUrl = url;
        callback(url);
      })
      .catch(() => {
        callback();
      });
  }

  // ---monitor visitType
  monitorVisitTypes(callback: Function) {
    const subscription = this.db
      .object("/organizations/" + this.user.orgID + "/settings/visitTypes/")
      .valueChanges()
      .subscribe((value: any) => {
        if (lodash.isFunction(callback)) {
          callback(value, subscription);
        }
      });
  }

  monitorStudentReasons(callback: Function) {
    const subscription = this.db.object("/organizations/" + this.user.orgID + "/settings/studentReasons/").valueChanges().subscribe((value: any) => {
      if (lodash.isFunction(callback)) {
        callback(value, subscription);
      }
    });
  }

  monitorSaStudentsReasons(callback: Function) {
    const subscription = this.db.object("/settings/defaults/studentReasons/").valueChanges().subscribe((value: any) => {
      if (lodash.isFunction(callback)) {
        callback(value, subscription);
      }
    });
  }

  updateVisitTypes(data: any, callback?: Function) {
    this.db
      .object("/organizations/" + this.user.orgID + "/settings/visitTypes/")
      .set(data);

    if (callback) {
      callback();
    }
  }
  updateStudentReasons(data: any, callback?: Function) {
    this.db
      .object("/organizations/" + this.user.orgID + "/settings/studentReasons/")
      .set(data);

    if (callback) {
      callback();
    }
  }

  updateSaStudentReasons(data: any, callback?: Function) {
    this.db.object("/settings/defaults/studentReasons/").set(data);
    if (callback) {
      callback();
    }
  }

  // ---monitor camera
  getCamera(callback: Function) {
    const subscription = this.db
      .object("/organizations/" + this.user.orgID + "/settings/camera/")
      .valueChanges()
      .subscribe((value: any) => {
        subscription.unsubscribe();

        if (lodash.isFunction(callback)) {
          callback(value, subscription);
        }
      });
  }

  updateCameraId(data: any, callback?: Function) {
    this.db
      .object("/organizations/" + this.user.orgID + "/settings/camera/deviceID")
      .set(data);

    if (callback) {
      callback();
    }
  }
  getTeammateValue(key) {
    return this.db.object(`/studios/${key}`).valueChanges()
  }
  getOrgManagerTypes(studioId) {
    return new Promise((resolve, reject) => {
      this.teammateService.getOrgManagerTypes().subscribe(orgManagerTypes => {
        if (orgManagerTypes) {
          this.getTeammateValue(studioId).subscribe((res: any) => {
            if (!res) return resolve('Teammate')
            let orgType = orgManagerTypes.filter(item => res.orgManagerType == item.key);
            if (orgType.length > 0) {
              resolve(orgType[0].tag)
            } else {
              resolve('Teammate')
            }
          })
        }
      })
    })
  }
  // --- cards
  getCards() {
    return new Promise(resolve => {
      // --- clean legacy url properties
      const cleanProperty = (cardName: string, property: string) => {
        return new Promise(resolve => {
          if (property.indexOf("Url") > -1) {
            this.db
              .object(
                "/organizations/" +
                this.user.orgID +
                `/settings/cards/design/` +
                cardName +
                "/" +
                property
              )
              .set(null)
              .then(() => {
                resolve('');
              });
          } else {
            resolve('');
          }
        });
      };
      // --- legacy code end

      const parseUrls = (cards: any, isDefault: boolean) => {
        return new Promise(async _resolve => {
          const promises = [];
          if (cards && cards.design) {
            lodash.forOwn(cards.design, (cardDesign, cardType) => {
              lodash.forOwn(cardDesign, (value, property) => {
                promises.push(
                  _ =>
                    new Promise(async resolveInner => {
                      const url = await this.getStorageUrl(value);
                      if (url) cardDesign[property + "Url"] = url;

                      if (isDefault === false)
                        await cleanProperty(cardType, property);

                      resolveInner('');
                    })
                );
              });
            });
          }

          for (let promise of promises) {
            await promise();
          }

          _resolve(cards);
        });
      };

      const subscription = this.db
        .object("/organizations/" + this.user.orgID + "/settings/cards/")
        .valueChanges()
        .subscribe(async (value: any) => {
          subscription.unsubscribe();

          if (lodash.isEmpty(value)) {
            this.getDefaults(async defaults => {
              resolve({
                cards: await parseUrls(
                  defaults.cards[this.user.org.type],
                  true
                ),
                isDefault: true
              });
            });
          } else {
            resolve({ cards: await parseUrls(value, false), isDefault: false });
          }
        });
    });
  }

  saveCardDesign(cardName: string, design: any) {
    return new Promise(resolve => {
      const updateDesign = () => {
        // --- clear design of any url properties, we don't store urls since we are generating on runtime
        lodash.forOwn(design, (value, property) => {
          if (property.indexOf("Url") > -1) design[property] = null;
        });

        this.db
          .object(
            "/organizations/" +
            this.user.orgID +
            "/settings/cards/design/" +
            cardName
          )
          .set(design)
          .then(() => {
            resolve('');
          });
      };

      this.getCards().then((result: any) => {
        if (result.isDefault === true) {
          this.db
            .object("/organizations/" + this.user.orgID + "/settings/cards/")
            .set(result.cards)
            .then(() => {
              updateDesign();
            });
        } else {
          updateDesign();
        }
      });
    });
  }

  saveCardsFile(file: any, fileName: string, callback: Function) {
    const orgLogoRef =
      "photos/organisations/" + this.user.orgID + "/cards/" + fileName;
    const imageRef = this.fireStorage.ref(orgLogoRef);

    let task: AngularFireUploadTask;
    task = imageRef.put(file);

    task
      .then(() => {
        imageRef
          .getDownloadURL()
          .toPromise()
          .then(url => {
            callback(orgLogoRef, url);
          })
          .catch(() => {
            callback();
          });
      })
      .catch(() => {
        callback();
      });
  }

  selectCardDesignByRole(designs: any, role: string) {
    return designs[role.toLowerCase() + "Badge"];
  }

  getStorageUrl(path: any) {
    return new Promise(resolve => {
      try {
        const imageRef = this.fireStorage.ref(path);
        imageRef.getDownloadURL().toPromise().then(url => {
          resolve(url);
        })
          .catch(() => {
            resolve('');
          });
      } catch (error) {
        resolve('');
      }
    });
  }

  getMainPrinter() {
    return new Promise(resolve => {
      const subscription = this.db.object("/organizations/" + this.user.orgID + "/settings/printers/main/").valueChanges().subscribe((value: any) => {
        subscription.unsubscribe();
        resolve(value);
      });
    });
  }

  // ================================================================================================
  // ================================================================================================
  // ================================================================================================

  getSuperAdminPrintSetupPromise(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.db.list(`/settings/orderPrintSetup/defaultPrint`).snapshotChanges().subscribe((res: any) => {
        let list: any[] = [];
        lodash.each(res, entry => {
          let value: any = entry.payload.val();
          if (typeof value === 'object') {
            value['key'] = entry.payload.key;
            value['isFrom'] = 'vip'
            list.push(value);
          }
        })
        resolve(list);
      }, (err) => {
        console.log('err: ', err);
        resolve([]);
      });
    })
  }

  getTeamMPrintSetupPromise(key: string, type: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.db.list(`/studios/${key}/settings/orderPrintSetup/defaultPrint`).snapshotChanges().subscribe((res: any) => {
        let list: any[] = [];
        lodash.each(res, entry => {
          let value: any = entry.payload.val();
          if (typeof value === 'object') {
            value['key'] = entry.payload.key;
            value['isFrom'] = type;
            list.push(value);
          }
        })
        resolve(list);
      }, (err) => {
        console.log('err: ', err);
        resolve([]);
      });
    })
  }

  getOrgPrintSetupPromise(key): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.object(`/organizations/${key}/settings/orderPrintSetup/defaultPrint`).valueChanges().subscribe((res: any) => {
        if(res) {
          resolve(res);
        } else {
          resolve('')
        }
      }, (err) => {
        console.log('err: ', err);
        resolve('');
      });
    })
  }

  getOrgData(orgId: string): Promise<string> {
    return new Promise(resolve => {
      let orgStudioSub = this.db.object(`/organizations/${orgId}`).valueChanges().subscribe((orgStudioId: any) => {
        orgStudioSub.unsubscribe();
        resolve(orgStudioId);
      })
    })
  }

  getOrgTypeIdUsingName(name: string) {
    return new Promise((resolve, reject) => {
      let orgTypeSub = this.db.list(`/org-types/`, ref => ref.orderByChild('name').equalTo(name)).snapshotChanges().subscribe(orgType => {
        orgTypeSub.unsubscribe();
        if (orgType.length > 0) {
          let orgTypeData: any;
          orgType.forEach(ele => {
            orgTypeData = ele.payload.val();
            orgTypeData.key = ele.payload.key;
          });
          resolve(orgTypeData);
        } else {
          resolve(null)
        }
      })
    })
  }

  // --- get studio data using studio id
  getStudio(id: string) {
    return new Promise((resolve, reject) => {
      if(!id) return resolve('');
      let studioSub = this.db.object(`/studios/${id}`).valueChanges().subscribe(data => {
        studioSub.unsubscribe();
        if(data) {
          let tempData: any = data;
          tempData['key'] = id;
          return resolve(tempData);
        } else {
          return resolve('')
        }
      })
    })
  }

  async getSuperAdminPrintSetupAndResolve(org) {
    return new Promise(async (resolve, reject) => {
      let saObj = await this.getSuperAdminPrintSetupPromise();
      let orgType: any = await this.getOrgTypeIdUsingName(org.type);
      if (orgType) {
        let specificOrgTypeData = saObj.findIndex(obj => { return obj.key == orgType.key })
        if (specificOrgTypeData > -1) {
          resolve(saObj[specificOrgTypeData]);
        } else {
          resolve('');
        } 
      }
    })
  }

  // --- set settings node at given path
  setSettings(value: any, subPath?: string) {
    let path = `settings`;
    if(subPath) path += `/${subPath}`;
    return this.db.object(path).set(value);
  }

  // --- update settings node at given path
  updateSettings(value: any, subPath?: string) {
    let path = `settings`;
    if(subPath) path += `/${subPath}`;
    return this.db.object(path).update(value);
  }

  // --- get settings node from given path
  getSettings(subPath?: string) {
    let path = `settings`;
    if(subPath) path += `/${subPath}`;
    return this.db.object(path).valueChanges();
  }

  readonly dbPathPrintPrices = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/settings/orderPrintSetup/defaultPrice`
  readonly userDBNodesPrintPrices = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null,
  }

  // --- get print prices by inheritance
  async getPrintPrices(
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error(
        "Params missing in getPrintPrices method call"
      );

    // --- fetch print prices data by inheritance
    let printPricesInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPathPrintPrices}`,
      "object",
      usersData,
      this.userDBNodesPrintPrices,
      null,
      useCache
    );

    // --- merge prices by inheritance
    let printPricesDetails;
    lodash.eachRight(printPricesInheritedData, item => {
      printPricesDetails = lodash.merge(printPricesDetails, item.data)
    })
    
    let ownerID = lodash.find(printPricesInheritedData, item => item.data)['ownerID'];
    if(printPricesDetails) printPricesDetails['ownerID'] = ownerID

    return printPricesDetails;
  }

  // --- update print defaults
  async updatePrintPrices(
    role: string,
    userID: string,
    updateObj: any
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID) || !updateObj)
      throw new Error(
        "Params missing in updatePrintPrices method call"
      );

    // --- prepare db path
    let replacementData = ReplacementTags.prepareReplacementData(
      this.userDBNodesPrintPrices[role],
      userID
    );
    let dbPath = this.inheritanceProvider.prepareDBPath(
      this.dbPathPrintPrices,
      replacementData
    );

    return await this.db.object(dbPath).update(updateObj)
  }

  // --- remove print prices
  async removePrintPrices(
    role: string,
    userID: string
  ) {
    // --- params verification
    if (!role || !userID)
      throw new Error(
        "Params missing in removePrintPrices method call"
      );

    // --- prepare db path
    let replacementData = ReplacementTags.prepareReplacementData(
      this.userDBNodesPrintPrices[role],
      userID
    );
    let dbPath = this.inheritanceProvider.prepareDBPath(
      this.dbPathPrintPrices,
      replacementData
    );

    return await this.db.object(dbPath).remove();
  }

  // --- remove print prices cache
  removePrintPricesCache(role, userID) {
    this.inheritanceProvider.removeCache(
      this.dbPathPrintPrices,
      this.userDBNodesPrintPrices[role],
      userID
    )
  }

  readonly dbPathPrintDefaults = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/settings/orderPrintSetup/defaultPrint`
  readonly userDBNodesPrintDefaults = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null,
  }

  // --- get print defaults by inheritance
  async getPrintDefaults(
    role: string,
    userID: string,
    orgTypeID?: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error(
        "Params missing in getPrintDefaults method call"
      );

    // --- fetch print defaults data by inheritance
    let printDefaultsInheritedData: any = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPathPrintDefaults}`,
      "object",
      usersData,
      this.userDBNodesPrintDefaults,
      null,
      useCache
    );
    
    let printDefaults = {};

    // --- if user role is org, set org data in org type key
    if(role == Role.ORG && orgTypeID) {
      let orgPrintDefaults = lodash.get(printDefaultsInheritedData, "[0].data")
      if(orgPrintDefaults) {
        printDefaultsInheritedData[0].data = {}
        printDefaultsInheritedData[0].data[orgTypeID] = orgPrintDefaults
      }
    }

    // --- merge print defaults data based on inheritance
    lodash.each(printDefaultsInheritedData, (item: any) => {
      let ownerID = item.ownerID;

      item.data = lodash.mapValues(item.data, (data: any) => {
        if(lodash.isObject(data)) {
          data["ownerID"] = ownerID;
        }
        return data;
      })

      lodash.each(item.data, (data, key) => {
        if(!printDefaults[key])
          printDefaults[key] = data;
      })
    });

    return orgTypeID ? lodash.get(printDefaults, orgTypeID, printDefaults) : printDefaults; 
  }

  // --- update print defaults
  async updatePrintDefaults(
    role: string,
    userID: string,
    updateObj: any
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID) || !updateObj)
      throw new Error(
        "Params missing in updatePrintDefaults method call"
      );

    // --- prepare db path
    let replacementData = ReplacementTags.prepareReplacementData(
      this.userDBNodesPrintDefaults[role],
      userID
    );
    let dbPath = this.inheritanceProvider.prepareDBPath(
      this.dbPathPrintDefaults,
      replacementData
    );

    return await this.db.object(dbPath).update(updateObj)
  }

  // --- remove print defaults
  async removePrintDefaults(
    role: string,
    userID: string
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error(
        "Params missing in removePrintDefaults method call"
      );

    // --- prepare db path
    let replacementData = ReplacementTags.prepareReplacementData(
      this.userDBNodesPrintDefaults[role],
      userID
    );
    let dbPath = this.inheritanceProvider.prepareDBPath(
      this.dbPathPrintDefaults,
      replacementData
    );
    
    return await this.db.object(dbPath).remove()
  }

  // --- remove print prices cache
  removePrintDefaultsCache(role, userID) {
    this.inheritanceProvider.removeCache(
      this.dbPathPrintDefaults,
      this.userDBNodesPrintDefaults[role],
      userID
    )
  }

  dbPathReasons = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/settings/reasons`;
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };

  // --- get list of crossing types by inheritance
  async getCrossingTypes(
    type: "student" | "visitor",
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error("Params missing in getCrossingTypes method call");

    // --- fetch crossing data by inheritance
    let dbPath = `${this.dbPathReasons}/${
      type == "student" ? "school_Student" : "school_Visitor"
    }`;
    let crossingTypesInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      dbPath,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- merge reasons data based on inheritance
    let crossingTypes = {};
    lodash
      .chain(crossingTypesInheritedData)
      .reverse()
      .each(reasonsData => {
        let ownerID = reasonsData.ownerID;
        let userCrossingTypes = lodash.mapValues(
          reasonsData.data,
          (data: any) => {
            return { ...data, ownerID };
          }
        );

        crossingTypes = lodash.merge(crossingTypes, userCrossingTypes);
      })
      .value();

    // --- check if "Others" should be there in reasons list, add it there if not already
    lodash.each(crossingTypes, (crossingType: any) => {
      if (crossingType.isOthersAllowed) {
        if (!lodash.has(crossingType.reasons, "other")) {
          lodash.set(crossingType.reasons, "other", { reasonName: "Other..." });
        }
      }
    });

    // --- remove crossing types & reasons with override hide flag
    crossingTypes = lodash
      .chain(crossingTypes)
      .mapValues((crossingType: any) => {
        // --- remove crossing type with override hide
        if (lodash.toLower(crossingType.Override) == "hide") return null;

        // --- remove reasons with override hide
        crossingType.reasons = lodash
          .chain(crossingType.reasons)
          .mapValues(reason => {
            if (lodash.toLower(reason.Override) == "hide") return null;

            return reason;
          })
          .omitBy(reason => lodash.isNil(reason))
          .value();

        return crossingType;
      })
      .omitBy(crossingType => lodash.isNil(crossingType))
      .value();

    return crossingTypes;
  }

  // --- get location data
  async getLocation(orgID: string, locationKey: string) {
    let snapshot = await this.db
      .object(`organizations/${orgID}/settings/locations/${locationKey}`)
      .query.once("value");
    return snapshot.val();
  }
}
